<template>
  <div style="width: 800px">
    <div style="color: gray;margin: 20px">广州高考网>{{ this.type.name }}</div>
    <div style="display: flex;justify-content: center;margin: 20px">
      <el-table
          :data="tableData"
          stripe
          :header-cell-style="{
          background: '#ffffff',
          color: '#1e8afc',
          fontWeight: '400',
          fontSize: '16px',
        }"
          style="width: 1200px;">
        <el-table-column
            prop="title"
            label="全部文档"

        >
          <template scope="scope">
            <div style="cursor: pointer;display: flex"
                 @click="openDetail(scope.row.id)">
              <!--              <span class="el-icon-s-fold" style="color: blue"></span>-->
              <div style="padding: 5px 10px;display: flex;justify-content: center">
                <img src="../../../assets/eduIcon.png" style="width: 20px;height:20px;" alt="">
              </div>

              <div style="padding: 5px 0">
                <span style="color: black;font-size: 15px;">{{ scope.row.title }}</span>
              </div>
            </div>
          </template>

        </el-table-column>

<!--        <el-table-column-->
<!--            align="right"-->
<!--            prop="mmDdTime"-->
<!--            label=""-->
<!--        >-->
<!--          <template scope="scope">-->
<!--            <div>-->
<!--              <span style="color: #666666;">{{ scope.row.mmDdTime }}</span>-->
<!--            </div>-->
<!--          </template>-->
<!--        </el-table-column>-->

      </el-table>

    </div>
    <div class="center">
      <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="page.currentPage"
          :page-size="page.pageSize"
          layout="total, prev, pager, next, jumper"
          :total="page.total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import {getConsultByStatus} from "../../../api/front/home";

export default {
  props: {
    dataType: {type: String}
  },
  watch: {
    // 利用watch方法检测路由变化：
    $route: function (to, from) {
      if (to.fullPath !== from.fullPath) {
        this.dataType = this.$route.params.dataType;
        this.load();
      }
    }
  },
  data() {
    return {
      // 导航栏类型数据
      dataType: '',
      // 列表数据
      tableData: [],
      // 类型数据
      type: [],
      // 分页数据相关
      page: {
        currentPage: 1,
        pageSize: 10,
        total: 0,
      },

    }
  },
  methods: {
    openDetail(id) {
      const url = this.$router.resolve('/front/edu/detail/' + id);
      window.open(url.href, '_blank');
    },
    load() {
      this.tableData = [];
      this.type = [];
      getConsultByStatus(Object.assign({
        current: this.page.currentPage,
        size: this.page.pageSize,
        dateType: this.dataType,
        id: this.id
      })).then(res => {
        const records = res.data.data.records[0];
        this.type = records.sysConsultType;
        this.tableData = records.sysConsults;
        this.page.total = res.data.data.total;
      })
    },

    toId() {
      const type = this.dataType;
      if (type === 'all') {
        this.id = this.$route.query.id;
      }
      this.load();
    },
    handleSizeChange(pageSize) {
      this.page.pageSize = pageSize
      this.load();
    },
    handleCurrentChange(currentPage) {
      this.page.currentPage = currentPage
      this.load();
    },
  },
  mounted() {
    this.dataType = this.$route.params.dataType;
    this.id = this.$route.query.id
    this.toId();
  }
}
</script>
<style lang="scss" scoped>
.center {
  display: flex;
  justify-content: center;

}
</style>
